
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,

  },
  setup(){
    return {
      router:useRouter()
    }
  },

  methods:{
    openMenu(){
      this.router.push('/menu')
    }
  }
});
