<template>
  <ion-page>

    
    <ion-content :fullscreen="true" >
      <div class="content" v-if="menu">

        <div class="menu-toggle" @click="menuOpen=true">

          <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 16.745c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm9-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-4-5c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>
        </div>

        <div class="overlay" :class="{open:menuOpen}">
          <img src="assets/bowa-logo.png">
          <!----><div class="categories">
          <template v-for="(category, i) in menu" :key="'cat-'+i">
          <div   class="category" :class="{active:i==categoryIndex}" @click="openCategory(i)">
            {{category['title_'+lang]}}
          </div> <br>
          </template>

        </div>
          <div class="langs">
            <div class="lang" :class="{active:lang=='en'}" @click="lang='en'">EN</div>
            <div class="lang" :class="{active:lang=='hr'}" @click="lang='hr'">HR</div>
          </div>
        </div>
        <!----><div class="scrollable">

      <header>
        <div class="inner-content" v-html="category['intro_'+lang]">
        </div>


      </header>
      <div class="item" v-for="(item) in items" :key="item.name_en" :class="{
        'category-separator':item.price==0&&!item.separator,
        'separator':item.separator
      }">
        <template v-if="item.separator">

        </template>
        <template v-else>
          <b>{{item['title_'+lang]}}</b>
          <template v-if="getIngredients(item)" >

          <span v-for="ingredient in getIngredients(item)" :key="item.name_en+ingredient">

             <i>/</i>{{ingredient}}

          </span>
          </template>
         <div class="price" v-if="item.price>0">{{item.price.toFixed(2)}} EUR</div>
<!--          <div class="price eur" v-if="item.price>0">{{ (item.price*7.5345).toFixed(2) }} kn</div>-->
         <div class="item-description">
          {{item['description_'+lang]}}
        </div>


        </template>








      </div>
        <div class="description" v-if="category['description_'+lang]" v-html="category['description_'+lang]">

        </div>


      </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent,  IonPage,} from '@ionic/vue';
import { defineComponent } from 'vue';
import {useRouter} from "vue-router";
import axios from "axios";
import { Preferences } from '@capacitor/preferences';

export default defineComponent({
  name: 'MenuPage',
  components: {
    IonContent,
    IonPage,

  },
  data(){
    return {
      menu:null,
      lang:'en',
      categoryIndex:0,
      menuOpen:true,
    }
  },
  mounted() {
    this.loadData();
    },
  setup(){
    return {
      router:useRouter()
    }
  },

  computed:{
    category(){
      if(this.menu!=null) {
        return this.menu[this.categoryIndex];
      }
      else {
          return null;
      }
    },
    items(){
      if(this.menu!=null) {
        return this.menu[this.categoryIndex].items.filter(x=>x.visible);
      }
      else {
        return null;
      }
    }
  },

  methods:{
      async loadData(){

        //get latest menu from local storage
        let latestMenu= await Preferences.get({key:'menu'});


        axios.get('https://menu.bowa-dubrovnik.com/getMenuV2.php').then(r=>{
          this.menu=r.data;
          Preferences.set({
            key:'menu',
            value:JSON.stringify(this.menu)
          }).then(()=>{
            console.log("Saved to local storage");
          })
        }).catch(e=>{

          if(latestMenu){
            this.menu=JSON.parse(latestMenu.value);
            console.log("Loaded from local storage");
          }
          else {
            alert("Menu loading has failed and no stored version was found." +
                " Please check your internet connection and try again.");
            this.router.back();
          }

        })
      },
      getIngredients(item){
        let parts=item['ingredients_'+this.lang];
        if(!parts) return false;
        return parts.split(',').map(x=>x.trim())
      },
      openCategory(i){
        this.categoryIndex=i;
        this.menuOpen=false;
      }
  }
});
</script>

<style scoped lang="scss">
.content {
  background: white;
  height: 100%;
  .overlay{
    width: 30%;
    left: 0;
    top: 10%;
    bottom: 10%;
    border-right: 2px solid #d2aa41;
    position: absolute;
    text-align: center;
    img{
      width: 70%;
    }
    .categories{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%,-50%);
      .category{
        font-family: "Bold", serif;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
        display: inline-block;
        line-height: 35px;
        margin-top: 10px;
        transition: .4s ease;
        border-bottom: 1px solid transparent;
        &.active {
          border-bottom: 1px solid #d2aa41;
        }
      }
    }
    .langs{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .lang{
        cursor: pointer;
        display: inline-block;
        padding: 10px;
        border: 1px solid transparent;
        transition: .4s ease;
        margin: 5px;
        border-radius: 50%;
        &.active{
          border-color: #d2aa41;
        }
      }
    }
    }
  .scrollable{
    position: absolute;
    top: 10%;
    bottom: 10%;
    right: 15px;
    left: 30%;
    overflow-y: scroll;
    .description{
      width: 75%;
      margin: 50px auto;
      text-align: center;
      font-size: 15px;
      line-height: 25px;
    }
    header{
      width: 80%;
      margin: 0 auto 50px;
      text-align: center;
      border-bottom: 1px solid #d2aa41;
      padding-bottom: 20px;
      position: relative;
      .inner-content{
        width: 80%;
        line-height: 1.5em;
        margin-left: 10%;
        font-family: "Bold", serif;
      }


    }
    .item{
      text-transform: uppercase;
      width: 80%;
      margin: 5px auto 15px;
      position: relative;
      padding-right: 100px;
      line-height: 20px;
      font-size: 14px;
      min-height: 30px;
      box-sizing: border-box;
      b{
        color: #d2aa41;
        font-weight: 600;
      }
    i {
      color: #d2aa41;
      margin: 0 3px;
    }
      .item-description{
        text-transform: none;
        font-size: 12px;
      }
      .price {
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
        width: 100px;
        &.eur{
          font-size: 0.9em;
          top: 18px;
        }
      }
      &.separator{
        height: 1px;
        min-height: auto;
        background: #000;
      }
      &.category-separator{
        margin: 2em auto;
        b{
          font-size: 18px;
          color: #000;
        }

      }
      }


    }

  .menu-toggle{
    display: none;
  }

  @media(max-width: 599px){
    .menu-toggle {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 80px;
      padding: 12px;
      cursor: pointer;
      svg{
        width: 100%;
        height: auto;
        path {
          fill: #d2aa41;
        }
      }
    }
    .overlay{
      width: 100%;
      top:0;
      bottom: 0;
      left:-100%;
      background: white;
      z-index: 3;
      transition: .3s ease;
      padding: 24px 0;
      .langs {
        bottom: 24px;
      }
      &.open {
        left: 0;
      }
    }
    .scrollable{
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .item {
        width: calc(100% - 24px);
        margin-left: 12px;
      }
      header {
        width:100%;
        padding-top: 20px;
        .inner-content img {
          margin-bottom: 12px!important;
        }
      }
    }
  }


  }


</style>
